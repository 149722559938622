document.addEventListener("DOMContentLoaded", function() {
    const swiperFooter = new Swiper('.footer-swiper', {
        direction: 'horizontal',
        slidesPerView: "auto",
        spaceBetween: 24,
        freeMode: true,
        
    });

    const swiperMain = new Swiper('.main-swiper', {
        slidesPerView: "auto",
        direction: 'horizontal',
        thumbs: {
            swiper: swiperFooter,
        },
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const swiperNews = new Swiper('.module-news__swiper', {
        direction: 'horizontal',
        slidesPerView: 3,
        spaceBetween: 24,
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            200: {
                slidesPerView: 1,
            },
            680: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
        }
    });

    const swiperGrid = new Swiper('.grid-swiper', {
        direction: 'horizontal',
        slidesPerView: 3,
        spaceBetween: 24,
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            200: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
        }
    });

    const swiperBaner = new Swiper('.baner-swiper', {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        }, 
    });
});