(function($){

    $('.faq-module__list_heading').on('click', function(e) {
        e.preventDefault();
        if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).next()
        .stop()
        .slideUp(300);
        } else {
        $(this).addClass('active');
        $(this).next()
        .stop()
        .slideDown(300);
        }
    });

})(jQuery);


document.addEventListener("DOMContentLoaded", function() {
    const dropdown = document.querySelector(".dropdown");
    if(dropdown) {
      const inputField = dropdown.querySelector("#inputField");
      const dropdownArrow = dropdown.querySelector(".dropdown-arrow");
      const dropdownMenu = dropdown.querySelector(".dropdown-menu");
      const dropdownOptions = dropdownMenu.querySelectorAll("li");
    
      function toggleDropdown() {
        dropdownMenu.classList.toggle("show");
        dropdownArrow.classList.toggle("rotate");
      }
    
      function selectOption() {
        const selectedOption = this.textContent;
        inputField.value = selectedOption;
        toggleDropdown();
      }
    
      inputField.addEventListener("click", toggleDropdown);
    
      dropdownOptions.forEach(function(option) {
        option.addEventListener("click", selectOption);
      });
    
      document.addEventListener("click", function(event) {
        const targetElement = event.target;
    
        if (!dropdown.contains(targetElement)) {
          dropdownMenu.classList.remove("show");
          dropdownArrow.classList.remove("rotate");
        }
      });
    }
  });


  // Отримуємо необхідні елементи з DOM
const inputMain = document.getElementById('inputMain');
const tabsArrow = document.querySelector('.tabs-arrow');
const filterSections = document.querySelector('.filter-sections');
const filterTabs = document.querySelectorAll('.filter-tab');


if (inputMain) {
    // Додаємо подію "клік" до inputMain
    inputMain.addEventListener('click', function() {
      filterSections.classList.toggle('tab-active');
      tabsArrow.classList.toggle('up');
    });

    // Додаємо подію "клік" до кожного filterTab
    filterTabs.forEach(function(tab) {
      tab.addEventListener('click', function() {
        const selectedValue = this.textContent;
        inputMain.value = selectedValue;
        filterSections.classList.remove('tab-active');
        tabsArrow.classList.remove('up');
      });
    });

    // Закриваємо дропдаун, якщо клік поза ним
    document.addEventListener('click', function(event) {
      const targetElement = event.target;
      if (!targetElement.matches('.filter-tab') && !targetElement.matches('#inputMain')) {
        filterSections.classList.remove('tab-active');
        tabsArrow.classList.remove('up');
      }
    });
}



