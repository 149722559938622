
const menuItems = document.querySelectorAll(".header_menu_gazda .menu-item-has-children");

function changeMenuItemStyle(parent) {
    const headerWrapper = document.querySelector('.header-wrapper');
    
    menuItems.forEach((item) => {
        if(item == parent) {
            
        } else {
            item.classList.remove('active-header-menu');
        }
    });

    parent.classList.toggle('active-header-menu');
    if(parent.classList.contains('active-header-menu')) {
        headerWrapper.classList.add('opened')

    } else {
        headerWrapper.classList.remove('opened')
    }
}

menuItems.forEach((item) => {
    item.addEventListener("click", () => {
    changeMenuItemStyle(item);
    });
});


document.addEventListener("DOMContentLoaded", function() {
    var closeButton = document.querySelector(".header-close");
    var headerMenu = document.querySelector(".header_menu_gazda");
    var headerWrapper = document.querySelector(".header_menu_wrapper");
    var headerHome = document.querySelector(".header_home");

    closeButton.addEventListener("click", function() {
        this.classList.toggle("header-active");
        headerMenu.classList.toggle("header_menu_gazda-active");
        headerWrapper.classList.toggle("header_menu_wrapper-active");
        headerHome.classList.toggle("header_home_active");
    });
});



// Функція для перевірки наявності класу в body та збереження в localStorage
function checkBodyClass() {
    var body = document.querySelector('body');
    var classesToCheck = ['budzzini', 'lvivskyy_potyag', 'gazda_cafe', 'medivnia', 'gazda_group'];
    var storedClass = null;

    for (var i = 0; i < classesToCheck.length; i++) {
        if (body.classList.contains(classesToCheck[i])) {
            storedClass = classesToCheck[i];
            break;
        }
    }

    // Зберігаємо значення класу в localStorage
    localStorage.setItem('bodyClass', storedClass);
}

// Викликаємо функцію перевірки при завантаженні сторінки
document.addEventListener('DOMContentLoaded', function() {
    var body = document.querySelector('body');
    var storedClass = localStorage.getItem('bodyClass');

    if (body.classList.contains('single-post')) {
        if (storedClass) {
            body.classList.add(storedClass);
        }
    } else {
        checkBodyClass();
    }
});


let buttonSearch = document.querySelector('.button_search');
let searchForm = document.querySelector('.searchform');


buttonSearch.addEventListener('click', function() {
    this.classList.toggle('cross_active');
    this.classList.toggle('search_active');
    searchForm.classList.toggle('searchform_active');
});


let languageName = document.querySelector('.language_name');
let languageShortcode = document.querySelector('.language_shortcode');

languageName.addEventListener('click', function() {
    languageShortcode.classList.toggle('language_shortcode-active');
});