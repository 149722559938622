// Отримуємо всі елементи input з типом "tel"
const telInputs = document.querySelectorAll('input[type="tel"]');
const inputField = document.getElementById('inputField');
const textInputs = document.querySelectorAll('input[type="text"]');

if (telInputs) {

    
    telInputs.forEach((input) => {
    input.addEventListener('input', handleTelInput);
    });

    
    function handleTelInput(event) {
    const input = event.target;

    
    const cleanedValue = input.value.replace(/\D/g, '');

    
    if (!cleanedValue.startsWith('380')) {
        input.value = '380';
    } else {
        
        const maxDigitsCount = 9;
        const digits = cleanedValue.slice(3, 3 + maxDigitsCount);
        input.value = '380' + digits;
    }
    }
}

if (textInputs) {

    textInputs.forEach((input) => {
        input.addEventListener('input', restrictDigitsInput);
      });
      
      
      function restrictDigitsInput(event) {
        const input = event.target;
      
        const inputValue = input.value;
      
        
        const lettersOnlyValue = inputValue.replace(/[^А-Яа-яA-Za-z]/g, '');
      
        input.value = lettersOnlyValue;
      }
}

if (inputField) {
    
    inputField.addEventListener('keydown', function(event) {
        event.preventDefault();
      });      
}





  