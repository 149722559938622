const filterSections = document.querySelector('.filter-sections');
const optionsBlog = document.querySelector('.options-blog');
const optionsEstablishments = document.querySelector('.options-establishments');
const optionsDate = document.querySelector('.options-date');
const optionsFeatures = document.querySelector('.options-features');
const labels = document.querySelectorAll('.options label');
const PaginationInput = document.querySelector('.pagination-input');

if (filterSections) {
  // Додаємо обробник події кліку до елемента filterSections
    filterSections.addEventListener('click', function(event) {
      const target = event.target;
      PaginationInput.value = '1';

      // Перевіряємо, чи клікнули на один з фільтрів
      if (target.classList.contains('filter-sections_blog')) {
        showOptions(optionsBlog);
        setActiveFilter(target);
      } else if (target.classList.contains('filter-sections_establishments')) {
        showOptions(optionsEstablishments);
        setActiveFilter(target);
      } else if (target.classList.contains('filter-sections_date')) {
        showOptions(optionsDate);
        setActiveFilter(target);
      } else if (target.classList.contains('filter-sections_features')) {
        showOptions(optionsFeatures);
        setActiveFilter(target);
      }
    });

    // Функція для приховування всіх варіантів та показу конкретного варіанту
    function showOptions(options) {
      const allOptions = document.querySelectorAll('.options');

      // Приховуємо всі варіанти
      allOptions.forEach(function(option) {
        option.style.display = 'none';
      });

      // Показуємо конкретний варіант
      options.style.display = 'block';
    }

    // Функція для встановлення активного фільтра
    function setActiveFilter(filter) {
      const allFilters = document.querySelectorAll('.filter-sections span');

      // Видаляємо клас "active" з усіх фільтрів
      allFilters.forEach(function(item) {
        item.classList.remove('active');
      });

      // Додаємо клас "active" до вибраного фільтра
      filter.classList.add('active');
    }


    // Додаємо обробник події "click" для кожного <label>
    labels.forEach(label => {
      label.addEventListener('click', () => {

        PaginationInput.value = '1';
        
        // Видаляємо клас "option_active" з усіх <label>
        labels.forEach(label => {
          label.classList.remove('option_active');
        });

        // Додаємо клас "option_active" до клікнутого <label>
        label.classList.add('option_active');
      });
    });

    // Отримання всіх елементів з класом "filter-tab"
    var filterTabs = document.querySelectorAll('.filter-tab');

    // Додавання обробника події "click" до кожного елемента "filter-tab"
    filterTabs.forEach(function(tab) {

      tab.addEventListener('click', function() {

        // Отримання всіх елементів input з типом "radio" у відповідному контейнері
        var radioInputs = document.querySelectorAll('.options-sections input[type="radio"]');
        
        // Зняття статусу "checked" з кожного input
        radioInputs.forEach(function(input) {
          input.checked = false;
        });
      });
    });

    document.querySelector('.filter-reload').addEventListener('click', function() {
        location.reload();
    });


    // Отримуємо посилання на кнопки та елемент input
    const buttons = document.querySelectorAll('.pagination-numbers button');
    const input = document.querySelector('.pagination-input');


    // Додаємо обробник події click до кожної кнопки
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const pageNumber = button.dataset.page; // Отримуємо значення атрибуту data-page
        input.value = pageNumber; // Передаємо значення у властивість value елемента input
        

        jQuery(function($) {
            $('#filter').change();
        });  
        
      });
    });

    const pagPrev = document.querySelector('.pagination-prev');
    const pagNext = document.querySelector('.pagination-next');
    // let currentPaginationValue = PaginationInput.value;
    // let currentPagValueButton = document.querySelectorAll('.pagination-numbers button');

    PaginationInput.addEventListener('change', function() {
      currentPaginationValue = PaginationInput.value;
      checkPagination();
    });



    if (pagPrev) {
      pagPrev.addEventListener('click', () => {
        var prInputValue = input.value;
        let prevInputValue = parseInt(prInputValue) - 1; 
        input.value = prevInputValue;
        
        console.log('11111111', currentPagValueButton);

        jQuery(function($) {
            $('#filter').change();
        });  
      });
    }

    if (pagNext) {
      pagNext.addEventListener('click', () => {
        var nxInputValue = input.value;
        let nextInputValue = parseInt(nxInputValue) + 1; 
        input.value = nextInputValue;
        
        console.log('222222222');

        jQuery(function($) {
            $('#filter').change();
        });  
      }); 
    }


    
}






jQuery( function( $ ){
	$( '#filter' ).change(function() {

		var filter = $(this);

		$.ajax({

			url : filter.data('url'), 
			data : filter.serialize(), 
			type : 'POST', 

			beforeSend : function( xhr ) {
				console.log('start');
			},
			success : function( data ) {
                console.log('start3')
				$( '#response' ).html(data);
          // Отримуємо посилання на кнопки та елемент input
          const buttons = document.querySelectorAll('.pagination-numbers button');
          const input = document.querySelector('.pagination-input');
          const pagPrev = document.querySelector('.pagination-prev');
          const pagNext = document.querySelector('.pagination-next');
          
          

          // Додаємо обробник події click до кожної кнопки
          buttons.forEach(button => {
            button.addEventListener('click', () => {
              input.value = '';
              var pageNumber = button.dataset.page; // Отримуємо значення атрибуту data-page
              input.value = pageNumber; // Передаємо значення у властивість value елемента input

              jQuery(function($) {
                  $('#filter').change();
              });  

              console.log('aaaaa', input);
            });
          });

          if (pagPrev) {
            pagPrev.addEventListener('click', () => {
              var prInputValue = input.value;
              let prevInputValue = parseInt(prInputValue) - 1; 
              input.value = prevInputValue;
              
              console.log('11111111');
  
              jQuery(function($) {
                  $('#filter').change();
              });  
            });
          }

          if (pagNext) {
            pagNext.addEventListener('click', () => {
              var nxInputValue = input.value;
              let nextInputValue = parseInt(nxInputValue) + 1; 
              input.value = nextInputValue;
              
              console.log('222222222');

              jQuery(function($) {
                  $('#filter').change();
              });  
            }); 
          }

           
			}
		});

		return false;
	});
});

