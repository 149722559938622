var wpcf7Elm = document.querySelector( '.wpcf7' );
var trigger = document.getElementById('trigger');
var modalWrapper = document.querySelector('.modal-wrapper');
var modalOverlay = document.querySelector('.modal-overlay');
var body = document.querySelector('body');
var done = document.querySelector('.module-reservation__done');
var content = document.querySelector('.module-reservation__content');
var play = document.querySelector('.play-button');
var singleVideo = document.querySelector('.module-single__video');
var singleImg = document.querySelector('.module-single__img');
var player;




if (wpcf7Elm) {

    wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
        
        modalWrapper.classList.add('modal-open');
        modalOverlay.classList.add('modal-wrapper__blur');
        body.classList.add('modal-body');
        done.classList.add('done_active');
        content.classList.add('content_active');
        
        
    }, false );
}





if (trigger) {
    trigger.addEventListener('click', function() {
        modalWrapper.classList.remove('modal-open');
        modalOverlay.classList.remove('modal-wrapper__blur');
        body.classList.remove('modal-body');

    });
}

var videoElement = document.getElementById('player');
if (play) {
    
    play.addEventListener('click', function(){
        play.classList.add('play_active');
        singleVideo.classList.add('video_active');
        singleImg.classList.add('img_active');
    });
}


var triggerModal = document.querySelectorAll('.gallery-trigger');
var closeModal = document.querySelector('.modal_btn-close');
var sectionModal = document.querySelector('.module-slider');


if (triggerModal) {

    triggerModal.forEach((trigger) => {
        trigger.addEventListener('click', function() {
            sectionModal.classList.add('modal-slider-open');
            body.classList.add('modal-body');
    
        });
    });

    if (closeModal) {
        closeModal.addEventListener('click', function() {
            sectionModal.classList.remove('modal-slider-open');
            body.classList.remove('modal-body');
        })
    } 
}


