
let mapSection = document.querySelector('.module-map');

if (mapSection) {



  function initMap() {
    // Координати центра карти
    var myLatLng = {lat: 49.83975984536191, lng: 24.026525193842943};

    // Налаштування стилів для карти
    var mapStyles = [
      {
        "featureType": "poi",
        "stylers": [
          {"visibility": "off"} // Вимикаємо відображення POI (point of interest - цікаві місця)
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {"color": "#AEBCE0"} // Колір вулиць
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {"color": "#C8D3EF"} // Колір карти
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {"color": "#3757A9"} // Колір води
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {"visibility": "off"} // Вимикаємо контур тексту міток
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {"visibility": "on"} // Включаємо текст міток
        ]
      }
      // Додайте інші стилі тут, якщо потрібно
    ];

    // Налаштування карти з використанням стилів та без кнопок плюс і мінус, а також кнопки для перегляду на весь екран
    var map = new google.maps.Map(document.getElementById('map'), {
      center: myLatLng,
      zoom: 14, // Змінюємо масштаб карти
      styles: mapStyles,
      zoomControl: false, // Вимикаємо кнопки плюс і мінус для приближення карти
      fullscreenControl: false, // Вимикаємо кнопку перегляду на весь екран
      mapTypeControl: false, // Вимикаємо вибір між супутником і картою
      streetViewControl: false // Вимикаємо можливість огляду вулиць
    });

    // Додавання міток з назвами вуличок
    var labels = ['Street 1', 'Street 2', 'Street 3']; // Замініть назви вуличок на власні

    var infowindows = [];

    labels.forEach(function(label) {
      var infowindow = new google.maps.InfoWindow({
        content: label
      });
      infowindows.push(infowindow);
    });

    var marker = new google.maps.Marker({
      position: myLatLng,
      map: map,
      // icon: '<?php echo $marker_icon; ?>' // Задаємо іконку маркера з ACF
    });

    
  }

  document.addEventListener('DOMContentLoaded', function() {
    initMap();
  });



  function initMap2() {
    // Координати центра карти
    
    var myLatLng2 = {lat: 49.843298, lng: 24.029907};

    // Налаштування стилів для карти
    var mapStyles2 = [
      {
        "featureType": "poi",
        "stylers": [
          {"visibility": "off"} // Вимикаємо відображення POI (point of interest - цікаві місця)
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {"color": "#F8997C"} // Загальний колір карти (вулички між будинками)
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {"color": "#FF6D40"} // Колір води
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {"color": "#F6C1B1"} // Колір площі між вулицями
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {"visibility": "off"} // Вимикаємо контур тексту міток
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {"visibility": "on"} // Включаємо текст міток
        ]
      }
      // Додайте інші стилі тут, якщо потрібно
    ];

    // Налаштування карти з використанням стилів та без кнопок плюс і мінус, а також кнопки для перегляду на весь екран
    var map2 = new google.maps.Map(document.getElementById('map2'), {
      center: myLatLng2,
      zoom: 14, // Змінюємо масштаб карти
      styles: mapStyles2,
      zoomControl: false, // Вимикаємо кнопки плюс і мінус для приближення карти
      fullscreenControl: false, // Вимикаємо кнопку перегляду на весь екран
      mapTypeControl: false, // Вимикаємо вибір між супутником і картою
      streetViewControl: false // Вимикаємо можливість огляду вулиць
    });

    // Додавання міток з назвами вуличок
    var labels = ['Street 1', 'Street 2', 'Street 3']; // Замініть назви вуличок на власні

    var infowindows = [];

    labels.forEach(function(label) {
      var infowindow = new google.maps.InfoWindow({
        content: label
      });
      infowindows.push(infowindow);
    });

    var marker = new google.maps.Marker({
      position: myLatLng2,
      map: map2,
      // icon: '<?php echo $marker_icon; ?>' // Задаємо іконку маркера з ACF
    });

    
  }

  document.addEventListener('DOMContentLoaded', function() {
    initMap2();
  });


  function initMap3() {
    // Координати центра карти
    var myLatLng3 = {lat: 49.838652, lng: 23.996819};

    // Налаштування стилів для карти
    var mapStyles3 = [
      {
        "featureType": "poi",
        "stylers": [
          {"visibility": "off"} // Вимикаємо відображення POI (point of interest - цікаві місця)
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {"color": "#D69775"} // Колір вулиць
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {"color": "#E2B59D"} // Колір карти
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {"color": "#CB7B50"} // Колір води
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {"visibility": "off"} // Вимикаємо контур тексту міток
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {"visibility": "on"} // Включаємо текст міток
        ]
      }
      // Додайте інші стилі тут, якщо потрібно
    ];

    // Налаштування карти з використанням стилів та без кнопок плюс і мінус, а також кнопки для перегляду на весь екран
    var map3 = new google.maps.Map(document.getElementById('map3'), {
      center: myLatLng3,
      zoom: 14, // Змінюємо масштаб карти
      styles: mapStyles3,
      zoomControl: false, // Вимикаємо кнопки плюс і мінус для приближення карти
      fullscreenControl: false, // Вимикаємо кнопку перегляду на весь екран
      mapTypeControl: false, // Вимикаємо вибір між супутником і картою
      streetViewControl: false // Вимикаємо можливість огляду вулиць
    });

    // Додавання міток з назвами вуличок
    var labels = ['Street 1', 'Street 2', 'Street 3']; // Замініть назви вуличок на власні

    var infowindows = [];

    labels.forEach(function(label) {
      var infowindow = new google.maps.InfoWindow({
        content: label
      });
      infowindows.push(infowindow);
    });

    var marker = new google.maps.Marker({
      position: myLatLng3,
      map: map3,
      // icon: '<?php echo $marker_icon; ?>' // Задаємо іконку маркера з ACF
    });

    
  }

  document.addEventListener('DOMContentLoaded', function() {
    initMap3();
  });


  function initMap4() {
    // Координати центра карти
    var myLatLng4 = {lat: 49.842563, lng: 24.026171};

    // Налаштування стилів для карти
    var mapStyles4 = [
      {
        "featureType": "poi",
        "stylers": [
          {"visibility": "off"} // Вимикаємо відображення POI (point of interest - цікаві місця)
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {"color": "#D69775"} // Колір вулиць
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {"color": "#E2B59D"} // Колір карти
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {"color": "#CB7B50"} // Колір води
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {"visibility": "off"} // Вимикаємо контур тексту міток
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {"visibility": "on"} // Включаємо текст міток
        ]
      }
      // Додайте інші стилі тут, якщо потрібно
    ];

    // Налаштування карти з використанням стилів та без кнопок плюс і мінус, а також кнопки для перегляду на весь екран
    var map4 = new google.maps.Map(document.getElementById('map4'), {
      center: myLatLng4,
      zoom: 14, // Змінюємо масштаб карти
      styles: mapStyles4,
      zoomControl: false, // Вимикаємо кнопки плюс і мінус для приближення карти
      fullscreenControl: false, // Вимикаємо кнопку перегляду на весь екран
      mapTypeControl: false, // Вимикаємо вибір між супутником і картою
      streetViewControl: false // Вимикаємо можливість огляду вулиць
    });

    // Додавання міток з назвами вуличок
    var labels = ['Street 1', 'Street 2', 'Street 3']; // Замініть назви вуличок на власні

    var infowindows = [];

    labels.forEach(function(label) {
      var infowindow = new google.maps.InfoWindow({
        content: label
      });
      infowindows.push(infowindow);
    });

    var marker = new google.maps.Marker({
      position: myLatLng4,
      map: map4,
      // icon: '<?php echo $marker_icon; ?>' // Задаємо іконку маркера з ACF
    });

    
  }

  document.addEventListener('DOMContentLoaded', function() {
    initMap4();
  });


}




  

 